@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Poppins:wght@100;200;300');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  background-color: rgb(247, 243, 243);
  margin: 0;
    overflow-x: hidden; /* Empêche le défilement horizontal */
  /* font-family: 'Manrope', sans-serif; */
font-family: 'Arial', sans-serif;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

