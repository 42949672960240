*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'open Sans', sans serif;
}

/* body {
    height: 100vh;
    background-color: #aa06db;
    background-size: cover;
    background-position: center;
} */

li{
list-style: none;
}

a{
    text-decoration: none;
    color: #ffff;
    font-size: 1rem;
}

a:hover{
    color: black;
}



header{
    position: fixed;
    height: 4rem;
    background-color: #aa06db;
    padding: 0 2rem;
    width: 100%;
}

.navbar{
    width: 100%;
    height: 60px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: relative; */
    background-color: #aa06db;


}

.navbar .logo a{
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: -65px;
}

.navbar .links{
    display: flex;
    gap: 2rem;
}

.navbar .toggle_btn{
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.action_btn{
    background-color: orange;
    color: #ffff;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    transition: scale 0.2 ease;
}

.action_btn:hover{
    scale: 1.05;
    color: #fff;
}

.action_btn:active{
    scale: 0.95;
}


.dropdown_menu{
    display: none;
    position: absolute;
    right: 2rem;
    top: 60px;
    height: 0;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    overflow: hidden;
    transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open{
    height: 240px;
}

.dropdown_menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropdown_menu .action_btn{
    width: 100%;
    text-align: center;
}



@media(max-width: 992px) {
    .navbar .links,
    .navbar .action_btn {
        display: none;
    }

    .navbar .toggle_btn{
        display: block;
    }

    .navbar .logo a{
        margin-left: -28px;
    }

    .dropdown_menu{
        display: block;
    }
}


@media(max-width: 576px) {
    .dropdown_menu{
        left: 2rem;
        width: unset;
    }
}




