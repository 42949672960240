ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

a{
   text-decoration: none;
}

.header{
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    position: fixed;
    width: 100%;
    min-height: 75px;
    background-color: #0000008d;
    color: #ffff;
}

.navLists{
    display: flex;
    margin-right: 20px;

}

.logo{
    margin-left: 5%;
}

.liste {
    margin-left: 22%;
}

.bout{
    margin-right: 5%;
}

.navigatebar{
    background-color: #aa06db;
    height: 10vh;
}

.navigatecontent{

    color: #ffff;
}

.nav-item{
    margin-left: 5px;
}


@media screen and (max-width:979px) {
    /* .navigatebar{
        height: 200px;
    } */
}