.hero {
    background-color: #aa06db;
    height: 80vh;
    width: 100%;
    /* border-radius: 0rem 0rem 25% 22rem; */
    border-radius: 100% 0% 66% 34% / 0% 90% 10% 60% ;
}

.huu{
    display: flex;
}

.setexte{
    margin-top: 20px;
}

.secc{
    margin-right: -3rem;
}
.herotexte{
    margin-top: 110px;
    margin-left: 90px;
    color: #f1f1f1;
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 1px;
}

.hero p{
    color: #f1f1f1;
    margin-top: 20px;
    margin-left: 90px;
}

.buy {
    margin-top: 10px;
    margin-left: 90px;
}

.frame1{
    /* margin-top: 80px; */
    width: 15vw;
    height: 30vh;
        /* height: 100px; */
}
.frame3{
    margin-top: 22rem;
    margin-left: -18rem;
        /* height: 100px; */
}
.frame4{
    margin-top: -15rem;
    margin-left: 18rem;
        /* height: 100px; */
}
.frame5{
    margin-top: -22rem;
    margin-left: 8rem;
        /* height: 100px; */
}
.frame6{
    margin-top: -8rem;
    margin-left: 8rem;
        /* height: 100px; */
}
.secc{
    margin-left: 9rem;
}

/* carte 1 */

.carte{
    width: 15vw;
    height: 30vh;
    background: #f1f1f1;
    border-radius: 6px;
    margin-top: 100px;
    margin-left: 50px;
    z-index: -1;
    /* padding: 0 20px;
    margin: 100px auto 0; */
}



.top-img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-top: 10px;
    margin-left: 10px;
}

.imgp {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 208px;
    height: 70px;
}

.line1{
    width: 20%;
    height: 10%;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: 12px;
    /* margin-left: 20px; */
}

.line2{
    width: 30px;
    height: 4px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: 12px;
    margin-left: 10px;
}

.lineblue{
    width: 10%;
    height: 7px;
    background: #2a0235;
    border-radius: 10px;
    margin-top: 5px;
}

.line4{
    width: 70px;
    height: 4px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: 7px;
    margin-left: 18px;
}

.content {
    margin-top: 15px;
}

.texte {
    margin-left: 10px;
    margin-right: 10px;
    width: 184px;
    height: 3px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: -3px;
    /* margin-left: 20px; */
}


/* carte 2 */

.carte2{
    width: 10vw;
    height: 20vh;
    background: #f1f1f1;
    border-radius: 3px;

    margin-top: 40px;
}


.top-img2{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-top: -4px;
    margin-left: 6px;
}

.imgp2 {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 140px;
    height: 50px;
}

.line12{
    width: 50px;
    height: 3px;
    background: #bab9b9;
    border-radius: 7px;
    margin-top: 6px;
    /* margin-left: 20px; */
}

.line22{
    width: 15px;
    height: 3px;
    background: #bab9b9;
    border-radius: 7px;
    margin-top: 6px;
    margin-left: 10px;
}

.lineblue2{
    width: 25px;
    height: 4px;
    background: #2a0235;
    border-radius: 5px;
    margin-top: 3px;
}

.line42{
    width: 50px;
    height: 3px;
    background: #bab9b9;
    border-radius: 7px;
    margin-top: 4px;
    margin-left: 10px;
}

.content2 {
    margin-top: 10px;
}

.texte2 {
    margin-left: 10px;
    margin-right: 10px;
    width: 115px;
    height: 3px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: -5px;
    /* margin-left: 20px; */
}


/* carte3 */

.carte3{
    width: 6vw;
    height: 14vh;
    background: #f1f1f1;
    border-radius: 2px;
    margin-left: 350px;
    margin-top: -420px;
}

.top-img3{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: -4px;
    margin-left: 5px;
}

.imgp3 {
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
    width: 94px;
    height: 30px;
}

.line13{
    width: 50px;
    height: 2px;
    background: #bab9b9;
    border-radius: 7px;
    margin-top: 6px;
    margin-left: 5px;
}

/* .line23{
    width: 15px;
    height: 3px;
    background: #bab9b9;
    border-radius: 7px;
    margin-top: 6px;
    margin-left: 10px;
} */

.lineblue3{
    width: 3px;
    height: 3px;
    background: #2a0235;
    border-radius: 2px;
    margin-top: 2px;
    margin-left: 4px;
}

.line43{
    width: 10px;
    height: 2px;
    background: #bab9b9;
    border-radius: 7px;
    margin-top: 3px;
    margin-left: 3px;
}

.content3 {
    margin-top: 12px;
}

.texte3 {
    margin-left: 5px;
    margin-right: 5px;
    width: 70px;
    height: 2px;
    background: #bab9b9;
    border-radius: 8px;
    margin-top: -5px;
    /* margin-left: 20px; */
}



/* carte 4  */

.carte4{
    width: 24vw;
    height: 25vh;
    background: #f1f1f1;
    border-radius: 6px;
    margin-top: 65px;
    margin-left: 200px;
    z-index: 1;
    position: relative;
    /* padding: 0 20px;
    margin: 100px auto 0; */
}

.carte5{
    width: 24vw;
    height: 25vh;
    background: #f1f1f1;
    border-radius: 6px;
    margin-top: 10px;
    margin-left: 200px;
    /* padding: 0 20px;
    margin: 100px auto 0; */
}



.top-img4{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 20px;
    margin-left: 30px;
}

.imgp4 {
    margin-top: 8px;
    margin-left: 76px;
    margin-right: 10px;
    width: 228px;
    height: 70px;
}

.line14{
    width: 70px;
    height: 4px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: 22px;
    margin-left: -5px;
}

.line24{
    width: 30px;
    height: 4px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: 22px;
    margin-left: 14px;
}

.lineblue4{
    width: 40px;
    height: 6px;
    background: #2a0235;
    border-radius: 10px;
    margin-top: 8px;
    margin-left: -4px;
}

.line44{
    width: 90px;
    height: 4px;
    background: #bab9b9;
    border-radius: 10px;
    margin-top: 9px;
    margin-left: 10px;
}

.sec1 {
    text-align: center;
    margin-top: 40px;
}

.titlesec1 {
    font-size: 16px;
    font-weight: bold;
}

.lin{
    margin: 0 auto;
    width: 5vw;
    height: 3px;
    background-color: orange;
}

.content-sec1 {
    margin-top: 20px;
}


/* div de trois column */

.sec1-1{
    margin-top: 40px;
}

.column{
    margin-left: 8px;
    margin-right: 8px;
}


.content {
    text-align: center; 
}

.image-sec1 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.title-content{
    font-size: 16px;
    font-weight: bold;
    margin-top: 14px;
}

.paragraphe-content {
    font-size: 15px;
}

/* .cercle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    /* border: 10px solid blue; */
    /* outline: 5px solid blue; */
    /* border-style: solid 10px;
    border-color: blue; 
} */

/* section 3 */

.sec3{
    margin-top: 62px;
}

.colorred{
    color: rgb(242, 48, 48);
}

.title-sec3{
    font-weight: bold;
    /* font-size: 20px; */
}

.paragraphe-sec3{
    margin-top: 10px;
    font-size: 13px;
    text-align: justify;
}

.sec33{
    margin-left: 40px;
}

.cardsec3{
    width: 19vw;
    height: 34vh;
    background: #f1f1f1;
    border-radius: 6px;
    margin-left: 19rem;
    z-index: -1;
    margin-top: -10px;
}

.imgcardsec3{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 15px;
    margin-top: 10px;
}

.titlecardsec3{
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
}

.paragraphcardsec3{
    margin-top: -10px;
    font-size: 10px;
    margin-left: 10px;
}

.imgpcardsec3{
    width: 100%;
    height: 60%;
}

.titlepcardsec3{
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.circular{
    margin-top: 16px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(254, 67, 67);
    text-align: center;
    color: white;
    font-size: 10px;
}

.card2sec3{
    width: 19vw;
    height: 21vh;
    background: #aa06db;
    border-radius: 6px;
    color: white;
    margin-left: 10rem;
    margin-top: -6rem;
    position: relative;

}

.linecard2sec3{
    margin-top:-10px;
}

.img3cardsec3{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 6px;
    margin-top: 13px;
}

.img4cardsec3{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 12px;
    margin-top: 13px;
}

.cardsec4{
    width: 19vw;
    height: 15vh;
    background: #f1f1f1;
    border-radius: 6px;
    margin-left: 5rem;
    margin-top: -12rem;
    z-index: 1;
    position: relative;

}

.frame10{
    margin-left: 20rem;
}
.frame11{
    margin-left: 11rem;
    margin-top: -1rem;
}
.frame12{
    margin-left: 8rem;
    margin-top: -20rem;
}
.lololo{
    margin-left: 4rem;
}


/* 
.cils {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: #3498db; Couleur de fond
    border-radius: 50%; Pour créer un cercle
    overflow: hidden; Pour masquer les pseudos-éléments qui dépassent
  
    Cils
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 30px;
      background-color: #fff; Couleur des cils
      transform-origin: 50% 100%; L'origine de la transformation pour simuler le pivot à la base des cils
    }
  
    Cil de gauche
    &::before {
      left: -5px;
      transform: rotate(-45deg);
    }
  
    Cil de droite
    &::after {
      right: -5px;
      transform: rotate(45deg);
    }
  } */

  .baleine {
    position: relative;
    width: 100px;
    height: 50px;
    background-color: #0b65a1; /* Couleur de fond */
    /* clip-path: polygon(0 0, 20% 0%, 25% 100%, 30% 0%, 50% 0%, 55% 100%, 60% 0%, 80% 0%, 100% 100%, 0% 100%);
     */
  
    /* Vous pouvez ajuster ces valeurs pour obtenir la forme souhaitée */
    clip-path: polygon(0% 0%, 10% 0%, 20% 10%, 30% 0%, 40% 10%, 50% 0%, 60% 10%, 70% 0%, 80% 10%, 90% 0%, 100% 100%);
    /* clip-path: polygon(0% 100%, 20% 0%, 80% 0%, 100% 100%); */
}

.sec5{
    margin-top: 150px;
}

.imgg{
    height: 450px;
    width: 290px;
    z-index: -1;
}

.cardsec5{
    width: 18vw;
    border-radius: 5px;
    height: 28vh;
    background-image: linear-gradient(
        to bottom,
        #f1f1f1 0%,
        #f1f1f1 50%,
        #aa06db 50%,
        #aa06db 100%
    ) ;
    text-align: center;
    margin-top: -170px;
    margin-left: 190px;
    position: relative;
    z-index: 1;
    
}

.titlecardsec5{
    padding-top:  15px;
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.imgpcardsec5{
    margin-top: -30px;
    width: 22%;
    height: 28%;
}

.title2cardsec5, .title3cardsec5, .title4cardsec5 {
    /* padding-top:  0px; */
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.title2cardsec5{
    padding-top: 10px;
}

.title4cardsec5{
    color: rgb(243, 109, 109);
    padding-top: 5px;
}


.sec5-2{
    margin-left: 45px;
    margin-top: 140px;
}

.vector{
    width: 100%;
    /* height: 100vh; */
    z-index: -1;
}

.jojo{
    margin-top: -15rem;
    margin-left: 10rem;
    color: white;
    font-size: 22px;
    font-weight: bold;
    /* position: relative;
    z-index: 1; */
}

.jojo1{
    margin-top: 8px;
    margin-left: 10rem;
    color: white;
    font-size: 22px;
    font-weight: bold;
}


.cercler{
    /* margin-top: -550px;
    margin-left: 600px; */
    margin-top: -30rem;
    margin-left: 40rem;
    z-index: 1;
    position: relative;
}

    .frame13{
        margin-left: -5.5rem;
        margin-top: 14rem;
    }

/* RESPONSIVITE DU HEADER */

@media(max-width: 1762px) {
    .frame5{
        margin-left: -10rem;
        margin-top: 10rem;
    }

}

@media(max-width: 1400px) {
    .frame6{
        margin-left: 8rem;
    }

}

@media(max-width: 1090px) {

    .setexte{
        margin-top: -20px;
    }
    /* .herotexte{
        /* margin-top: 2px; 
        margin-left: 90px;

    }
    
    .hero p{
        margin-top: 20px;
        margin-left: 90px;
    }
    
    .buy {
        margin-top: 10px;
        margin-left: 90px;
    } */
    
    .frame1{
        margin-top: -90px;
            /* height: 100px; */
     }

     .frame3{
        margin-left: -15rem;
    }

    .frame4{
        margin-top: -46rem;
        margin-left: 18rem;
            /* height: 100px; */
    }
    .frame5{
        margin-top: -30rem;
        margin-left: 8rem;
            /* height: 100px; */
    }
    .frame6{
        margin-top: -15rem;
        margin-left: 8rem;
            /* height: 100px; */
    }

}

@media(max-width: 1088px) {
    .frame3{
        margin-left: -12rem;
    }

    .secc{
        margin-left: 95px;
    }
}

@media(max-width: 955px) {
    .huuu{
        display: block;
    }

}

@media(max-width: 898px) {
    .frame3{
        margin-left: -9rem;
    }

    .secc{
        margin-left: 60px;
    }
}

@media(max-width: 820px) {
    .frame1{
        /* margin-top: 80px; */
        width: 20vw;
        height: 25vh;
            /* height: 100px; */
    }
    .frame3{
       
        width: 20vw;
        height: 23vh;    }
    .frame4{
        width: calc(10vw - 2rem);
        height: 12vh;
        margin-top: -40rem;
        margin-left: -2rem;
     }
    .frame5{
         width: 15vw;
        height: 25vh;
    }
    .frame6{
        width: 15vw;
        height: 25vh;
    }
}

@media(max-width: 772px) {
    .secc{
        display: none;
        visibility: hidden;
    }

    .setexte{
        display: block;
        justify-content: center;
        margin-top: 20px;
    }

    .herotexte{
        font-size: 45px;
        font-weight: bold;
        letter-spacing: 1px;
    }
    
    .hero p{
        color: #f1f1f1;
        font-size: 20px;
    }
    
    .buy {
        font-size: 20px;
    }

}

@media(max-width: 562px) {
    .setexte{

        margin-top: -10px;
        margin-left: -35px;


    }

}

@media(max-width: 410px) {
    .setexte{

        margin-top: -40px;
        margin-left: -35px;


    }

}


/* RESPONSIVITE DE LA SECTION 2 */

@media(max-width: 1402px) {

    .lololo{
        margin-left: 0;
    }

}

@media(max-width: 1090px) {

    .lololo{
        margin-left: -20px;
    }

    .frame10{
        margin-left: 15rem;
    }
    .frame11{
        margin-left: 6rem;
        margin-top: -1rem;
    }
    .frame12{
        margin-left: 4rem;
        margin-top: -22rem;
    }
}

@media(max-width: 1090px) {
    .sec33{
        margin-left: 20px;
    }

}

@media(max-width: 994px) {
    .lololo{
        margin-top: 10px;
    }
    .sec33{
        margin-left: -20px;
    }

}

@media(max-width: 768px) {

    .lololo{
        margin-top: 50px;
    }
}

@media(max-width:600px) {
    .lololo{
        margin-left: 14px;
    }
    .sec33{
        margin-left: 14px;
    }
}

@media(max-width:576px) {
    .paragraphe-sec3{
        text-align: justify;
    }

    .sec33{
        align-items: center;
        margin: 0;
    }

}

@media(max-width:535px) {
    .lololo{
        margin-left: -25px;
    }

}

@media(max-width:492px) {
    .lololo{
        margin-left: -50px;
    }

}

@media(max-width:453px) {
    .frame10{
        /* margin-right: 2px; */
        width: 200px;
    }
    .frame11{
        /* margin-right: 2px; */
        width: 50vw;
    }

}


/* RESPONSIVITE DE LA SECTION 4 */

@media(max-width: 993px) {


.frame13{
    /* margin-left: -5.5rem; */
        display: none;
}

.sec5-2{
    margin-top: 40px;
}

}

@media(max-width: 770px) {


    .frame13{
        margin-left: 14rem;
            display: block;
            margin-top: -10rem;
    }


}

@media(max-width: 455px) {


    .frame13{
            display: none;
    }

    .kokoko{
        /* align-items: center; */
        margin-left: 4rem;
    }

}
@media(max-width: 435px) {


    .frame13{
            display: none;
    }

    .kokoko{
        /* align-items: center; */
        margin-left: 2rem;
    }

}

@media(max-width: 1090px) {

    .sec5-2{
        margin-left: -5px;
    }
}


/* responcivite footer */

@media(max-width: 1016px) {
    .jojo{
        margin-top: -6rem;
        margin-left: 10rem;
    }
}
@media(max-width: 511px) {
    .jojo{
        margin-top: -5rem;

        /* display: flex; */
    }

    .jojo, .jojo1 {
        margin-left: 50px;
    }
}